<template lang="pug">
v-tabs(
  show-arrows
  next-icon="mdi-arrow-right-bold-box-outline"
  prev-icon="mdi-arrow-left-bold-box-outline")
  v-tabs-slider(color="primary")
  div.d-flex.align-items-center(v-for="tab in tabs" :key="tab.id")
    v-tab(:to="tab.link" v-if="tab.hasOwnProperty('checkAccess') ? tab.checkAccess() : true").p-2 {{$t(tab.label)}}
</template>

<script>
export default {
  props: {
    tabs: { type: Array, default: () => [] }
  }
}
</script>
